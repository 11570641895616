import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const state = reactive({
    poid: route.query.poid,
    cancel: route.query.cancel && JSON.parse(route.query.cancel),
    result: {}
  })

  const fnResult = async () => {
    let res
    if (state.cancel) {
      res = await proxy.$CashSvc.postCashCargeCancelResult(route.query)
    } else {
      res = await proxy.$CashSvc.postCashCargeResult(route.query)
    }
    if (res.resultCode === '0000') {
      state.result = res
    } else {
      alert(res.resultMsg)
    }
  }

  //init
  const init = async () => {
    await fnResult()
  }

  init()

  return {
    ...toRefs(state)
  }
}
