<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">
                  캐시 충전 {{ cancel ? '취소' : '' }}
                </button>
              </div>
              <div class="right"></div>
            </div>
            <div v-if="cancel && !$Util.isEmpty(result)">
              <div class="cash_point_table">
                <div class="cash_point_table_paper">
                  <dl>
                    <dd class="cash_point_table_row">
                      취소 일시
                      <span>{{ result.cancel.paymentDate }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제 수단
                      <span>{{ result.cancel.paymentType }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제금액
                      <span>{{
                        $Util.formatNumber2(
                          result.cancel.price + result.cancel.fee,
                          {
                            unit: ''
                          }
                        )
                      }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      수수료
                      <span>{{
                        $Util.formatNumber2(result.cancel.fee, { unit: '' })
                      }}</span>
                    </dd>
                    <dt class="cash_point_table_row mt total">
                      취소 금액
                      <span
                        >-
                        {{
                          $Util.formatNumber2(result.cancel.price, { unit: '' })
                        }}</span
                      >
                    </dt>
                  </dl>
                </div>
              </div>
              <div class="cash_point_table disabled">
                <div class="cash_point_table_paper">
                  <dl>
                    <dd class="cash_point_table_row">
                      충전 일시
                      <span>{{ result.charge.paymentDate }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제 수단
                      <span>{{ result.charge.paymentType }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제금액
                      <span>{{
                        $Util.formatNumber2(
                          result.charge.price + result.charge.fee,
                          {
                            unit: ''
                          }
                        )
                      }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      수수료
                      <span>{{
                        $Util.formatNumber2(result.charge.fee, { unit: '' })
                      }}</span>
                    </dd>
                    <dt class="cash_point_table_row mt total">
                      충전 금액
                      <span>{{
                        $Util.formatNumber2(result.charge.price, { unit: '' })
                      }}</span>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="cash_point_table">
                <div class="cash_point_table_paper">
                  <dl>
                    <dd class="cash_point_table_row">
                      결제 상태
                      <span
                        class="fw500"
                        :class="{
                          plus: result.state == '0',
                          red: result.state == '1'
                        }"
                        >{{
                          result.state == '0'
                            ? '승인'
                            : result.state == '1'
                            ? '취소'
                            : ''
                        }}</span
                      >
                    </dd>
                    <dd class="cash_point_table_row">
                      충전 일시
                      <span>{{ result.paymentDate }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제 수단
                      <span>{{ result.paymentType }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      결제금액
                      <span>{{
                        $Util.formatNumber2(result.price + result.fee, {
                          unit: ''
                        })
                      }}</span>
                    </dd>
                    <dd class="cash_point_table_row">
                      수수료
                      <span>{{
                        $Util.formatNumber2(result.fee, { unit: '' })
                      }}</span>
                    </dd>
                    <dt class="cash_point_table_row mt total">
                      충전금액
                      <span>{{
                        $Util.formatNumber2(result.price, { unit: '' })
                      }}</span>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-cash-charge-report-index',
  setup() {
    return { ...componentState() }
  }
}
</script>
